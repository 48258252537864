<script setup>
const props = defineProps(["userName", "otherNames"]);

const avatars = computed(() => {
  const toReturn = {};

  const firstInitial = props.userName[0];
  const secondInitial = props.otherNames[0][0];

  const numRemaining = props.otherNames.length - 1;

  toReturn.list = [
    { initial: firstInitial },
    { initial: secondInitial, colorClass: "bg-primary-half" },
  ];

  if (numRemaining) {
    if (numRemaining == 1) {
      toReturn.list.push({
        initial: props.otherNames[1][0],
        colorClass: "bg-primary-dark",
      });
      toReturn.tooltip = `You, ${props.otherNames[0]} & ${props.otherNames[1]}`;
    } else {
      toReturn.tooltip = `You, ${props.otherNames[0]} & ${numRemaining} others`;

      toReturn.list.push({
        initial: `+${numRemaining}`,
        colorClass: "bg-primary-dark",
      });
    }
  } else {
    toReturn.tooltip = `You & ${props.otherNames[0]}`;
  }

  return toReturn;
});
</script>

<template>
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger class="cursor-default">
        <div
          :class="{
            'relative h-[40px]': true,
            'w-[70px]': avatars.list?.length == 2,
            'w-[100px]': avatars.list?.length == 3,
          }"
        >
          <CircleAvatar
            v-for="(avatar, index) in avatars.list"
            :initials="avatar.initial"
            :colorClass="avatar.colorClass"
            class="absolute border-[3px] border-mono-white"
            :class="{
              'left-0 z-[2]': index == 0,
              'left-[30px] z-[1]': index == 1,
              'left-[60px] z-0': index == 2,
            }"
          />
        </div>
      </TooltipTrigger>
      <TooltipContent>{{ avatars.tooltip }}</TooltipContent>
    </Tooltip>
  </TooltipProvider>
</template>
